<template>
    <div class="container">
        <van-swipe @change="onChange" :autoplay="3000" id="moBasePtSwipe" :show-indicators="false" ref="swiper">
            <van-swipe-item v-for="(item,index) in list" :key="index" @click="$router.push(item.path)">
              <img :src="item.src" alt="" class="noSwiperImg">
              <div class="moBaseContent">
                <div class="swiperContentTitle">{{item.title}}</div>
                <p class="swiperContentDesc">{{item.p}}</p>
              </div>
            </van-swipe-item>
          </van-swipe>
          <div class="moBasePtIndexCon">
            <div v-for="(item,index) in list" :key="index" :class="{moBasePtIndex:true,moBasePtIndexActive:navIndex===index}" @click="$refs.swiper.swipeTo(index)"></div>
          </div>
    </div>
</template>

<script>
export default {
  name: 'moSwiper',
  data () {
    return {
      navIndex: 0
    }
  },
  props: {
    list: {
      type: Array,
      default: () => {
        return null
      }
    }
  },
  methods: {
    onChange (index) {
      this.navIndex = index
    }
  }
}
</script>

<style scoped>
.container{
    width: 100%;
}
img {
   object-fit: cover;
}
#moBasePtSwipe img{
  width: 100%;
  min-height: 8.75rem;
  display: block;
}
.moBaseContent{
  border-radius: .25rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  border-top: 0;
  background-color: #fff;
  padding:1rem
}

.moBasePtIndexCon{
  width: 100%;
  margin: 1rem 0 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.moBasePtIndex{
  width: .75rem;
  height: .25rem;
  background-color: #E6E6E6;
  border-radius: .1875rem;
  margin-right: .625rem;
}
.moBasePtIndexActive {
  background-color: #D70C0C;
}
.swiperContentTitle{
font-size: 1rem;
font-weight: 550;

}
.swiperContentDesc{
  font-size: 14px;
  color: #444;
  line-height: 1.25rem;
  margin-top: .5rem;
}
.noSwiperImg{
  height: 200px;
  width: 100%;
  object-fit: cover;
}
</style>
